import axios from 'axios';

import { Toast,Dialog } from 'vant';

import cookie from '../utils/CookieUtil'

import router from '@/router'

axios.defaults.baseURL = process.env.NODE_ENV == 'development' ? 'http://localhost:8088/' : 'https://chunfast.lchuning.com/'
// axios.defaults.baseURL = ''
axios.defaults.withCredentials = true
// axios.defaults.headers['token'] = cookie.getCookie(cookie.token) || ''
axios.defaults.headers.post['Content-Type'] = 'application/json'

// http request 拦截器
axios.interceptors.request.use(config=>{
        cookie.getCookie(cookie.token)==''?cookie.getCookie(cookie.token):config.headers['token']=cookie.getCookie(cookie.token)
　　　　return config;
　　},err=>{
　　　　console.log(err)
　　})

// http response 拦截器
axios.interceptors.response.use(
  response => {
      let data = response.data;
      if(data.code==401){
        Dialog.confirm({
          title: data.msg,
          message: '是否重新登录?',
        }) .then(() => {
          console.log("重新登录");
          router.push('/login')
        }) .catch(() => {
          console.log('取消登录');
        });
        return;
      }
      if(data.code!=0){
        Toast.fail(data.msg);
        return data.code;
      }else{
        return data.data;
      }
  },
  error => {
      console.log("error");
    if (error.response) {
        Toast.fail("请求失败"+error.response.status);
    }
    return Promise.reject(error.response.data)   // 返回接口返回的错误信息
  });

export default axios;
