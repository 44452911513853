<template>
  <div id="nav">
  </div>
  <router-view/>
  <nut-tabbar v-if="this.$store.state.CURRENTHREF" @tab-switch="tabSwitch" bottom active-color="rgb(189 76 51)" :visible='this.$store.state.tabbar'>
    <nut-tabbar-item tab-title="首页" icon="home" v-on:click="herf('/')"></nut-tabbar-item>
    <nut-tabbar-item tab-title="淘特" icon="cart" v-on:click="fn()"></nut-tabbar-item>
    <nut-tabbar-item tab-title="我的" icon="my" v-on:click="herf('/mine')"></nut-tabbar-item>
  </nut-tabbar>
</template>
<script>

export default {
  name: 'app',
  data(){
    return {
    }
  },
  methods:{
    herf(name){
      
      this.$router.push(name)
    },
    fn(){
      window.location.href = 'https://s.click.taobao.com/eiJkgcu';
    }
  },
  mounted(){

  },
  destroyed () {
    console.log("app 刷新是否执行");
  }
}
</script>
<style>
body{
  margin: 0;
  padding: 0;
  background:#f5f5f5 ;
}
.icon {
  width: 38px; height: 38px;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
#app .nut-tabbar{
  background: #f5f5f5;
  border-top: 1px solid #8c7878;
}
</style>
