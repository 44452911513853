let setCookie = function(cname,cvalue,time){
    let d = new Date();
    d.setTime(d.getTime()+(time*1000));
    let expires = "expires="+d.toGMTString();
    //document.cookie = cname+"="+cvalue+"; "+expires;
    localStorage.setItem(cname,cvalue)
}

let  getCookie = function(cname){
    /*let name = cname + "=";
    let ca = document.cookie.split(';');
    for(var i=0; i<ca.length; i++) {
        let c = ca[i].trim();
        if (c.indexOf(name)==0) { return c.substring(name.length,c.length); }
    }*/
    return localStorage.getItem(cname)
}
let token = 'token';
module.exports = {setCookie,getCookie,token};