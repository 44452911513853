<template>
  <div class="home">
    <van-cell-group>
      <van-cell title="" value="投产计算器" />
      <van-cell title="成本" >
        <van-stepper v-model="cost" step="0.1" />
      </van-cell>
      <van-cell title="售价" >
        <van-stepper v-model="price" step="0.1" />
      </van-cell>
      <van-cell title="投产" :value="(price/(price-cost)).toFixed(2)" />
    </van-cell-group>
  </div>
</template>
<script>
export default {
  name: 'Home',
  data(){
    return {
      cost:0,
      price:0
    }
  }
}
</script>
