import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import cookie from './utils/CookieUtil'
import axios from './api/index';
import VueAxios from 'vue-axios'
import ShareImpl from './utils/WeixinShareApi'

let url = window.location.href.split("#")[0];
    if(url.indexOf('from') != -1){
      url = window.location.href.split("?")[0];
    }
    ShareImpl({
      shareTitle: '青柠生活',
      shareDesc: '三网话费、国家电网、外卖红包、淘宝优惠券;自用省钱、推广赚钱;便民，优惠...',
      shareUrl: url,
      shareImg: 'http://img.lchuning.com/login.png'
    });

import { Tabbar,TabbarItem ,Layout, Swiper,SwiperItem, Icon,Avatar,Row,Col,Price } from '@nutui/nutui'
import '@nutui/nutui/dist/style.css'
import "./assets/iconfont/iconfont.js"
import "./assets/font_2ytqwvfc4bz/iconfont.js"
import {Stepper,NoticeBar ,Search,List,Field,Divider,Toast,Dialog,Notify,Overlay,Area,DropdownMenu, DropdownItem,SwipeCell,Button,Badge,Card,Pagination,Swipe, SwipeItem,Lazyload,Picker,Popup,Cell,CellGroup,NavBar,Tab, Tabs,Tag,Grid, GridItem,Image as VanImage,ActionSheet,Rate,ActionBar, ActionBarIcon, ActionBarButton,Loading} from 'vant';
import 'vant/lib/index.css';
createApp(App)
//nutui框架
.use(Avatar).use(Icon).use(Tabbar).use(Layout).use(Swiper).use(Row).use(Col).use(TabbarItem).use(SwiperItem ).use(Price)
//vant框架
.use(NoticeBar).use(Search).use(List).use(Field).use(Divider).use(Toast).use(Cell).use(CellGroup).use(VanImage).use(ActionSheet).use(Rate).use(ActionBar).use(ActionBarIcon).use(ActionBarButton)
.use(Dialog).use(Notify).use(Overlay).use(Area).use(DropdownMenu).use(DropdownItem).use(SwipeCell).use(Button).use(Badge).use(Card).use(Pagination).use(Swipe).use(SwipeItem).use(Lazyload)
.use(Picker).use(Popup).use(NavBar).use(Tab).use(Tabs).use(Tag).use(Grid).use(GridItem).use(Loading).use(Stepper)
//其他
.use(store).use(router)
.use(VueAxios,axios)
.mount('#app');


router.beforeEach((to, from, next) => {
    if(!to.meta.isOpen){
      Toast.fail('正在开发中，暂未开放!');
      return;
    }
    //设置页面标题
    document.title = to.meta.title;
    //设置是否展示tabbar
    store.state.tabbar = to.meta.tabbar;
    //设置tabbar位置
    store.state.CURRENTHREF = to.meta.currenthref;
    //获取登录token
    let token = cookie.getCookie(cookie.token);
    //判断是否需要登录与是否登录
    if(to.meta.login && !token){
      Dialog.confirm({
        title: to.meta.title,
        message: '需要登录，是否登录?',
        confirmButtonText:"登录"
      }) .then(() => {
          // on confirm
          router.push('/login');
        }) .catch(() => {
          //设置页面标题
          document.title = from.meta.title;
          //设置是否展示tabbar
          store.state.tabbar = from.meta.tabbar;
          //设置tabbar位置
          store.state.CURRENTHREF = from.meta.currenthref;
          // on cancel
          router.push('/');

        });
    }else{
      window.scrollTo(0, 0);
      next();
    }
  })
