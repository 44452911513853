import wx from 'weixin-js-sdk';
import axios from "../api/index";

const ShareImpl = function (option) {
    //option是分享的配置内容*/
    axios.post('/app/share/config', option).then((response) => {
        wx.config({
            debug: false,
            appId: response.appid,
            timestamp: response.timestamp,
            nonceStr: response.noncestr,
            signature: response.signature,
            jsApiList: [
                'updateAppMessageShareData',
                'updateTimelineShareData'
            ]
        });
    }).catch(error => {
        window.console.log(error);
    });
    wx.ready(function () {
        wx.updateAppMessageShareData({
            title: option.shareTitle,
            desc: option.shareDesc,
            link: option.shareUrl,
            imgUrl: option.shareImg
        });
        wx.updateTimelineShareData({
            title: option.shareTitle,
            desc: option.shareDesc,
            link: option.shareUrl,
            imgUrl: option.shareImg,
        });
    })
    /*}*/
}

export default ShareImpl