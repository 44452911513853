import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Index.vue'),
    meta: { title: '首页', currenthref: true, isOpen:true,tabbar:0 }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue'),
    meta: { title: '关于', isOpen:process.env.NODE_ENV == 'development'  }
  },
  {
    path: '/home',
    name: 'CS',
    component: () => import('../views/Home.vue'),
    meta: { title: '投产计算器', isOpen:true  }
  },
  {
    path: '/index',
    name: 'Index',
    redirect: '/'
  },
  {
    path: '/mine',
    name: 'Mine',
    component: () => import('../views/Mine.vue'),
    meta: { title: '个人中心', currenthref: true,tabbar:2,login:true, isOpen:true  }
  },
  {
    path: '/query',
    name: 'Query',
    component: () => import('../views/Query.vue'),
    meta: { title: '我的订单',login:true, isOpen:true }
  },
  {
    path: '/notes',
    name: 'Notes',
    component: () => import('../views/Notes.vue'),
    meta: { title: '收支明细',login:true, isOpen:true  }
  },
  {
    path: '/recharge/:recommendUserId',
    name: 'Recharge',
    component: () => import('../views/Recharge.vue'),
    meta: { title: '充值中心',login:true, isOpen:true  },
    alias: '/rechargeplus'
  },
  {
    path: '/withdrawal/:amount',
    name: 'Withdrawal',
    component: () => import('../views/Withdrawal.vue'),
    meta: { title: '提现中心',login:true, isOpen:true }
  },
  {
    path: '/bindAlipay',
    name: 'BindAlipay',
    component: () => import('../views/BindAlipay.vue'),
    meta: { title: '绑定支付宝账号',login:true, isOpen:true }
  },
  {
    path: '/income',
    name: 'Income',
    component: () => import('../views/Income.vue'),
    meta: { title: '钱包明细',login:true, isOpen:true }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: { title: '账号登录', isOpen:true }
  },
  {
    path: '/registerplus/:phoneNumber',
    name: 'Register',
    component: () => import('../views/Register.vue'),
    meta: { title: '账号注册', isOpen:true },
    alias: '/register'
  },
  {
    path: '/share',
    name: 'Share',
    component: () => import('../views/Share.vue'),
    meta: { title: '分享充值',login:true, isOpen:true  }
  },
  {
    path: '/changepw',
    name: 'ChangePassword',
    component: () => import('../views/ChangePassword.vue'),
    meta: { title: '修改密码', isOpen:true }
  },
  {
    path: '/wechatpay/:signPay',
    name: 'WechatPay',
    component: () => import('../views/WechatPay.vue'),
    meta: { title: '微信支付确认', isOpen:true }
  },
  {
    path: '/memberRecharge',
    name: 'MemberRecharge',
    component: () => import('../views/MemberRecharge.vue'),
    meta: { title: '会员充值', isOpen:process.env.NODE_ENV == 'development' }
  },
  {
    path: '/memberRechargePay',
    name: 'MemberRechargePay',
    component: () => import('../views/MemberRechargePay.vue'),
    meta: { title: '会员充值下单', isOpen:process.env.NODE_ENV == 'development' }
  },
  {
    path: '/electricityRecharge',
    name: 'ElectricityRecharge',
    component: () => import('../views/ElectricityRecharge.vue'),
    meta: { title: '家庭缴费', isOpen:process.env.NODE_ENV == 'development' }
  },
  {
    path: '/rechargeElectricityBill',
    name: 'RechargeElectricityBill',
    component: () => import('../views/RechargeElectricityBill.vue'),
    meta: { title: '家庭缴费',login:true, isOpen:true }
  },
  {
    path: '/accountNumberDescription',
    name: 'AccountNumberDescription',
    component: () => import('../views/AccountNumberDescription.vue'),
    meta: { title: '户号说明', isOpen:true }
  },
  {
    path: '/accountNumber',
    name: 'AccountNumber',
    component: () => import('../views/AccountNumber.vue'),
    meta: { title: '户号列表',login:true, isOpen:true }
  },
  {
    path: '/addAccountNumber',
    name: 'AddAccountNumber',
    component: () => import('../views/AddAccountNumber.vue'),
    meta: { title: '添加账户',login:true, isOpen:true }
  },
  {
    path: '/team',
    name: 'Team',
    component: () => import('../views/Team.vue'),
    meta: { title: '团队列表',login:true, isOpen:true }
  },
  {
    path: '/upgradeMember',
    name: 'UpgradeMember',
    component: () => import('../views/UpgradeMember.vue'),
    meta: { title: '付费升级',login:true, isOpen:true }
  },
  {
    path: '/shareLogin',
    name: 'ShareLogin',
    component: () => import('../views/ShareLogin.vue'),
    meta: { title: '分享注册',login:true, isOpen:true  }
  },
  {
    path: '/withdrawalList',
    name: 'WithdrawalList',
    component: () => import('../views/WithdrawalList.vue'),
    meta: { title: '提现记录',login:true, isOpen:true }
  },
  {
    path: '/teamOrder',
    name: 'TeamOrder',
    component: () => import('../views/TeamOrder.vue'),
    meta: { title: '团队充值',login:true, isOpen:true }
  },
  {
    path: '/wxLogin',
    name: 'WxLogin',
    component: () => import('../views/WxLogin.vue'),
    meta: { title: '登录中...',login:false, isOpen:true }
  },
  {
    path: '/mtActivity',
    name: 'mtActivity',
    component: () => import('../views/MtActivity.vue'),
    meta: { title: '美团活动中心',login:false, isOpen:process.env.NODE_ENV == 'development' }
  },
  {
    path: '/pshop',
    name: 'PShop',
    component: () => import('../views/PShop.vue'),
    meta: { title: '商城中心', isOpen:true }
  },
  {
    path: '/pshopdesc/:id',
    name: 'PShopDesc',
    component: () => import('../views/PShopDesc.vue'),
    meta: { title: '商品详情', isOpen:true }
  },
  {
    path: '/electricityRecharge',
    name: 'PShopDesc',
    component: () => import('../views/ElectricityRecharge.vue'),
    meta: { title: '商品详情', isOpen:true }
  },
  {
    path: '/rechargeProxy',
    name: 'rechargeProxy',
    component: () => import('../views/RechargeProxy.vue'),
    meta: { title: '充值中心',login:false, isOpen:true  }
  },
  {
    path: '/electricityRechargeProxy',
    name: 'ElectricityRechargeProxy',
    component: () => import('../views/RechargeElectricityBillProxy.vue'),
    meta: { title: '家庭缴费',login:false, isOpen:true }
  },
  {
    path: '/rechargeGasBill',
    name: 'RechargeGasBill',
    component: () => import('../views/RechargeGasBill.vue'),
    meta: { title: '家庭缴费',login:true, isOpen:true }
  },
  {
    path: '/gasSustain',
    name: 'GasSustain',
    component: () => import('../views/GasSustain.vue'),
    meta: { title: '家庭缴费',login:false, isOpen:true }
  }
  ,
  {
    path: '/canxuanCommodityAll',
    name: 'canxuanCommodityAll',
    component: () => import('../views/CanxuanCommodityAll.vue'),
    meta: { title: '商品检索',login:true, isOpen:true }
  }
  ,
  {
    path: '/canxuanCommodityHotGoods',
    name: 'CanxuanCommodityHotGoods',
    component: () => import('../views/CanxuanCommodityHotGoods.vue'),
    meta: { title: '选品库',login:true, isOpen:true }
  }
  ,
  {
    path: '/cmmJxGoodsDetails',
    name: 'CmmJxGoodsDetails',
    component: () => import('../views/CmmJxGoodsDetails.vue'),
    meta: { title: '商品详情',login:true, isOpen:true }
  },
  {
    path: '/costShopping',
    name: 'CostShopping',
    component: () => import('../views/CostShopping.vue'),
    meta: { title: '成本购样',login:true, isOpen:true }
  }
  ,
  {
    path: '/newClass',
    name: 'NewClass',
    component: () => import('../views/NewClass.vue'),
    meta: { title: '热点新闻',login:false, isOpen:true }
  }

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
