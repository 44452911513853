import { createStore } from 'vuex'

export default createStore({
  state: {
    CURRENTHREF: "",//存储页面
    tabbar:null
  },
  mutations: {
    setCURRENTHREF(state, currenthref){
      state.CURRENTHREF = currenthref;
    },
    setTabbar(tabbar){
      state.tabbar = tabbar;
    }
  },
  actions: {
  },
  modules: {
  }
})
